import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { Container } from "../components/utils"

const FaqPage = ({ data: { page } }) => {
  const locale = "en"
  return (
    <Layout locale={locale}>
      <SEO title={page.title} lang={locale} />
      <Header title={page.title} locale={locale} theme="light" />
      <Container>
        {page.faqs.map(faq => (
          <section key={faq.id}>
            {faq.title}
            <div dangerouslySetInnerHTML={{ __html: faq.text }} />
          </section>
        ))}
      </Container>
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
  query FaqPageEnQuery {
    page: datoCmsFaqsPage(locale: { eq: "en" }) {
      title
      faqs {
        text
        title
        id
      }
    }
  }
`
